import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Recap`}</h1>
    <p><strong parentName="p">{`Yo,`}</strong></p>
    <p><strong parentName="p">{`We’re officially onto Project #2 entirely - where we show you how to create an online course that drives over $1M+ in sales.`}</strong></p>
    <p><strong parentName="p">{`We're test driving a new Sunday weekly recap style for you...so let us know what you think of this (and if it's helpful) by smashing reply!`}</strong></p>
    <p><strong parentName="p">{`Weekly Post Recap:`}</strong></p>
    <p><strong parentName="p">{`Reading List – 📖`}</strong></p>
    <p>{`1 - `}<a parentName="p" {...{
        "href": "https://minesafetydisclosures.com/blog/newyorktimes"
      }}>{`This slide deck`}</a>{` breaking down the New York Times business. `}<strong parentName="p">{`I LOVE`}</strong>{` this guys slides. He did one for costco a while back that was amazing too. (I bought $100k+ of NYT stock after reading this...that’s how convincing it is) - `}<em parentName="p">{`I sent this on Friday too, but it's that good that i want to make sure you saw it!`}</em></p>
    <p>{`2 - `}<a parentName="p" {...{
        "href": "https://www.paulallen.net/2007/05/25/prediction-facebook-will-be-the-largest-social-network-in-the-world/"
      }}>{`This blog post from Paul Allen`}</a>{` (founder of Ancestry.com) back in 2007 when he made a bunch of predictions that at the time seemed unlikely. (eg. That facebook would reach 1B users). I love reading old blog posts. When you see how right/wrong they were back then, it teaches you to think for yourself now and take all news/opinions with a grain of salt.`}</p>
    <p>{`3 - Speaking of predictions - checkout Metaculus. It’s a website where you can make predictions & earn points based on if they were right and wrong. It looks like the prediction for “`}<a parentName="p" {...{
        "href": "https://www.metaculus.com/questions/3859/will-it-be-reported-that-trump-has-tested-positive-for-covid-19-in-2020/"
      }}>{`will it be reported that Trump tests positive for COVID-19 in 2020?`}</a>{`” - the crowdsourced wisdom had this at 25% before it happened!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      